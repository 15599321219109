import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Lottie from 'react-lottie'
import animationData from '../lottie/404.json'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
        margin: 'auto',
      }}
    >
      <div style={{ width: '90%', height: '90%' }}>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid meet',
            },
          }}
          height="100%"
          width="100%"
        />
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
